$base-color: #111;
$background-color: #FFF;
$footer-background: #E2F0F6;
$text-header: 14px;
$text-body: 12px;

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin underline($color) {
  color: $color;
  position: relative;
  width: fit-content;

  &::after {
    content: "";
    width: 100%;
    height: .2em;

    background: $color;

    position: absolute;
    bottom: -6px;
    left: 0;

  }
}

*{
  margin: 0;
  padding: 0;
  font-family: system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Noto Sans,Ubuntu,Cantarell,Helvetica Neue,Oxygen,Fira Sans,Droid Sans,sans-serif;
}

body {
  height: 100vh;
}

#sidenav {
  display: none;
  height: 100%;
  width: 86%;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: $background-color;
  overflow-x: hidden;
  transition: 0.5s;
  padding: 17% 8%;
  &.show {
    display: block;
  }

  button {
    @include flex-center;

    width: 15.5rem;
    gap: 0.5rem;
    background-color: #302F43;
    border: none;
    border-radius: 50px;
    color: $background-color;
    padding: 0.4rem 1rem;
    font-size: $text-header;
    font-weight: 600;
    margin: 2rem 0;
  }

  hr {
    margin: 1.5rem 0;
  }

  #account {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: $text-header;
  }

  p {
    font-size: $text-header;
    font-weight: 600;
  }

  a:not(.remove) {
    margin: 1rem 0;
    text-decoration: none;
    font-size: $text-header;
    color: $base-color;
    display: block;
    transition: 0.3s;
  }

  #closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
  }

  .sidebar__item {
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    &:last-child {
      margin-bottom: 0px;
      border-bottom: none;
      padding-bottom: 0px;
    }
    h5 {
      margin-bottom: 8px;
      font-size: $text-header;
    }
    .sidebar-checkbox {
      margin-bottom: 1rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      .row {
        padding: 0.3rem 0.5rem;
        background-color: #F8F8FA;
        color: #484849;
        border-radius: 15px;
        font-size: 12px;
        &.checked-item {
          background-color: #DDD;
        }
        .form-checkbox {
          display: none;
        }
        .doc_count {
          display: none;
        }
        b {
          font-weight: normal;
        }
      }
    }
  }

}

body {
  padding-top: 52px !important;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 8;
  background: #FFF;
  height: 52px;
  padding: 0 1rem;

  img {
    position: absolute;
    top: 25%;
  }

  .header-search {
    position: absolute;
    right: 14px;
    top: 14px;
  }

  #logo {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

#search-block {
  height: 150px;
  background: url("/sites/all/themes/uim/assets/images/aerial-view-colorful-mixed-forest-shrouded-morning-fog-beautiful-autumn-day 1.webp");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 0 1rem;

  display: flex;
  align-items: center;

  h2{
    padding-left: 4px;
    color: $background-color;
    font-size: 22px;
    font-weight: 600;
  }

  #input-container {
    position: absolute;
    height: 60px;
    width: 89%;
    top: 200px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 1rem;
    background-color: $background-color;
    border-radius: 12px;

    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 0.5px solid #676767;

    @include flex-center;

    justify-content: space-between;

    input {
      border-style: none;

      &:focus,
      :active {
        outline: none;
      }

      &::placeholder {
        color: $base-color;
        font-size: $text-header;
        font-weight: 500;
      }
    }
  }
}

#categories {
  overflow: scroll;
  display: flex;
  gap: 8vw;

  margin-top: 3rem;
  padding: 0 1rem;
  padding-top: 4px;

  &.region {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  a {
    @include flex-center;

    flex-direction: column;
    gap: 0.3rem;

    text-decoration: none;
    color: #111;
    font-weight: 600;

    .icon {
      @include flex-center;

      width: 48px;
      height: 48px;
      border-radius: 50px;
      position: relative;

      img {
        width: 20px;
        height: 20px;
      }
      &.active::before {
        content: '';
        position: absolute;
        top: -4px;
        right: -4px;
        bottom: -4px;
        left: -4px;
        border: 2px solid #1EBE5F;
        border-radius: 50px;
      }
    }

    .red {
      background-color: #FE0000;
    }

    .yellow {
      background-color: #F69A06;
    }

    .green {
      background-color: #1EBE5F;
    }

    .pink {
      background-color: #FF4F80;
    }

    .blue {
      background-color: #29C5FE;
    }

    span {
      font-size: $text-body;
    }
  }
}

#popular {
  height: 160px;
  margin-top: 3rem;

  h3 {
    margin-bottom: 1rem;
    font-size: $text-header;
    padding-left: 1rem;
  }

  #scroll-container {
    height: 79%;
    overflow: scroll;
    display: flex;
    gap: 0.7rem;
    padding-left: 1rem;

    .popular-item {
      position: relative;
      flex: 0 0 6rem;
      border-radius: 6%;
      overflow: hidden;

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        filter: brightness(70%);
      }

      h5 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $background-color;
        text-shadow: $base-color 1px 1px 1px;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}

#interesting-proposal {
  padding: 0 1rem;
  margin-top: 3rem;

  h3 {
    margin-bottom: 1rem;
    font-size: $text-header;
  }

  #grid-container {
    height: 15rem;
    display: grid;
    grid-template-columns: repeat(6, 2fr);
    grid-template-rows: repeat(3, 2fr);
    grid-column-gap: 0.5rem;
    grid-row-gap: 0.5rem;

    .grid-item {
      display: flex;
      flex-direction: column;
      justify-content: end;
      gap: 0.2rem;
      color: $background-color;
      text-shadow: $base-color 1px 1px 1px;
      position: relative;
      height: 75px;

      div {
        position: absolute;
        padding: 0.5rem;
      }

      h5 {
        font-size: 12px;
      }

      span {
        font-size: $text-body;
      }

      img {
        height: 100%;
        border-radius: 6px;
        object-fit: cover;
        filter: brightness(70%);
      }

      &:nth-child(1) {
        grid-area: 1 / 1 / 3 / 4;
        height: 155px;

        h5 {
          font-size: 16px;
        }

        div {
          padding: 1rem;
        }
      }

      &:nth-child(2) {
        grid-area: 3 / 1 / 4 / 3;
      }

      &:nth-child(3) {
        grid-area: 3 / 3 / 4 / 5;
      }

      &:nth-child(4) {
        grid-area: 3 / 5 / 4 / 7;
      }

      &:nth-child(5) {
        grid-area: 1 / 4 / 2 / 7;
      }

      &:nth-child(6) {
        grid-area: 2 / 4 / 3 / 7;
      }

    }
  }
}

#traveling {
  padding: 0 1rem;
  margin-top: 3rem;

  h3 {
    font-size: $text-header;
  }

  .travel-card {
    display: flex;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 0.1);
    gap: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    text-decoration: none;
    color: #111;

    .card-image {
      width: 40%;
      position: relative;

      .discount-badge {
        position: absolute;
        bottom: 20px;
        left: 0;
        background-color: #dc3545 !important;
        padding: 5px 15px;
        color: #FFF;
        z-index: 1;
        font-size: 12px;
      }

      .chips-list.on-image {
        margin: 0.5rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.1rem;
        position: absolute;
        z-index: 1;
        margin-left: 8px;

        .chips {
          padding: 0.3rem 0.5rem;
          background-color: #F8F8FA;
          color: #484849;
          border-radius: 15px;
          font-size: 11px;
        }
      }

      .favorite {
        position: absolute;
        width: 1.3rem;
        height: 1.3rem;
        top: 0px;
        right: 0px;
        margin: 0.5rem;
        display: none;
      }
    }

    img {
      width: 100%;
    }

    .info-block {
      display: flex;
      flex-direction: column;
      gap: 0.2rem;
      padding: 0.5rem 0 1rem 0;
      width: 50%;

      h5 {
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 4px;
      }

      .address {
        font-size: 0.8rem;
      }

      .nearest {
        font-size: 0.8rem;
        margin-top: 8px;
      }

      .price-info {
        font-size: 0.7rem;
        margin-bottom: 3px;
      }

      .infotext,
      .trail-info {
        font-size: 0.8rem;
      }

      .price-main {
        font-size: 1rem;
        font-weight: bold;
        color: #fe0903;
      }

      .info-item {
        display: flex;
        align-items: start;
        gap: 0.2rem;
        font-size: $text-body;
      }
      .info-block-inner {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        font-size: $text-body;
      }

      img {
        width: 0.8rem;
        margin-top: 3px;
      }

      .chips-list {
        margin: 0.5rem 0;
        display: flex;
        flex-wrap: wrap;
        gap: 0.1rem;

        .chips {
          padding: 0.3rem 0.5rem;
          background-color: #F8F8FA;
          color: #484849;
          border-radius: 15px;
          font-size: 12px;
        }
      }

    }

    &.list-style {
      gap: 1rem;
      align-items: stretch;
      .card-image {
        min-height: 190px;
        position: relative;
        overflow: hidden;
        img {
          position: absolute;
          top:0;
          bottom: 0;
          height: 100%;
          width: auto;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.node-page-rest.node-inner {
   #traveling {
     margin-top: 0;
   }
   .description {
     padding-bottom: 0;
   }
   iframe {
     width: 100% !important;
   }
}

.node-page-blog {
  h1 {
    font-size: 16px;
    margin: 50px 1rem 0px 1rem;
  }
  .description {
    img {
      width: 100% !important;
      height: auto !important;
      margin: 10px 0;
    }
    ul, ol {
      margin-left: 20px;
      li {
        margin-bottom: 8px;
      }
    }
  }
}
.page-blog-inner {
  padding: 1rem;
  font-size: $text-body;
  h1 {
    font-size: 18px;
    margin: 20px 0 10px 0;
  }
  .view-content {
    .card {
      margin: 0 0 15px 0;
      padding: 0 0 15px 0;
      border-bottom: 0.2px solid #D9D9D9;
      h6 {
        font-size: $text-header;
        margin: 0 0 8px 0;
      }
      img {
        float: left;
        width: 100px;
        margin: 0 8px 0px 0;
      }
    }
  }
}
.page-rest-inner {
  padding: 1rem 0;
  h1 {
    padding: 1rem;
    padding-bottom: 0;
  }
  .view-header {
    padding: 1rem;
    padding-bottom: 0;
  }
}

.node-inner {
  h4.prices-title {
    padding: 0.6rem 1rem 0 1rem;
    font-weight: normal;
  }
  .photo {
    position: relative;

    button#arrow-back,
    button#like,
    button#share,
    button#location-name {
      position: absolute;
      background-color: transparent;
      border: none;
      margin: 1rem;
    }

    .slider {
      overflow: scroll;
      display: flex;
    }

    .icons {
      width: 24px;
      height: 24px;
    }

    #like {
      left: 92%;
      transform: translate(-100%, 0);
    }
    #share {
      top: 80px;
      left: 92%;
      transform: translate(-100%, -100%);
    }
    #location-name {
      top: 70%;
      color: #FFF;
      font-size: 1.4rem;
      text-shadow: 0px 0px 3px #000;
      .weather {
        text-align: left;
        font-size: 1rem;
        line-height: 30px;
        display: flex;
        img {
          height: 40px;
        }
      }
    }
    #background-photo {
      height: 100%;
      width: -webkit-fill-available;
      object-fit: cover;
    }

    .map-3d-video {
      position: relative;
      cursor: pointer;
      overflow: hidden;
      background-size: cover;
      background-position: 50%;
    }
    #mapboxmapinner {
      position: relative;
      width: 100% !important;
      height: 400px !important;
    }
    .map-3d-video-play {
      position: absolute;
      height: 50px;
      width: 50px;
      border-radius: 50%;
      background-color: #fd2026;
      display: grid;
      place-content: center;
      box-shadow: 0px 0px 0.5em #FFF;
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px;
    }
    .map-3d-video:hover .map-3d-video-play {
      box-shadow: 0px 0px 1em #FFF;
    }
    .map-3d-video-play svg {
      width: 30px;
      height: 30px;
      fill: #FFF;
      stroke: #FFF;
      margin-left: 7px;
    }
    .mapboxgl-ctrl-logo {
      display: none !important;
    }

    p {
      position: absolute;
       bottom: 0;
      left: 92%;
      transform: translate(-100%, -100%);
      color: #FFF;
      font-size: 12px;
      margin: 1rem;
    }
  }

  nav {
    display: flex;
    padding: 1rem 1rem 1.2rem 1rem;
    box-shadow: 0 2px 0 rgb(245, 245, 245);

    a {
      font-size: 13px;
      font-weight: 600;
      text-decoration: none;
      color: #484849;
      margin-right: 15px;
    }

    a.active {
      @include underline(#1EBE5F);
    }
  }

  .main-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem;

    .info-text {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      h2 {
        font-size: 18px;
      }

      p {
        color: #893847;
        font-size: 14px;
      }

      .address {
        color: #111;
      }
    }

    .message-button {
      height: 30px;
      width: 50px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;

      @include flex-center;
    }
  }

  &.node-page {
    .main-info {
      margin-top: 30px;
    }
  }

  .prices {
    padding: 1rem;
    display: flex;
    gap: 5rem;

    p {
      font-size: 12px;
    }

    .price {
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 0.5rem;
    }
    &.two-per-row {
      flex-wrap: wrap;
      gap: 0;
      div {
        flex: 50%;
        padding-bottom: 15px;
        &:last-child {
          padding-bottom: 0px;
        }
      }
    }
  }

  .features {
    h2 {
      font-size: 14px;
    }

    .chips-list {
      margin-top: 0.5rem;
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;

      .chips {
        padding: 0.3rem 0.5rem;
        background-color: #F8F8FA;
        color: #484849;
        border-radius: 15px;
        font-size: 12px;
      }
    }
  }

  .subtitle {
    font-size: $text-header;
    font-weight: bold;
  }

  #interesting-proposal,
  #traveling {
    margin-top: 20px;
  }
  #interesting-proposal {
    margin-bottom: 20px;
  }

  .comments {
    padding: 1rem;
    font-size: $text-body;
    .container-comments {
      margin-top: 10px;
    }
    textarea {
      width: 100%;
      padding: 1rem;
      background-color: #FFF;
      border-radius: 6px;
      box-sizing: border-box;
      border: 0.5px solid #676767;
      margin-top: 6px;
    }
    .group-rate {
      display: none;
    }
    .form-submit {
      height: 33px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;
      padding: 0 20px;
      margin-top: 10px;
    }
    .field-name-field-comment-photos {
      margin: 10px 0;
      label,
      .description {
        display: none;
      }
      fieldset {
        border: none;
      }
      table thead,
      table .image-widget-data,
      .tabledrag-toggle-weight-wrapper {
        display: none;
      }
      .image-preview img {
        width: 100px !important;
        height: 100px !important;
      }
      .image-widget-data {
        width: 100%;
        .droppable {
          border: 0.5px solid #676767;
          border-radius: 6px;
          width: 100%;
          .droppable-message {
            line-height: 49px;
          }
          .droppable-standard-upload {
            min-height: auto;
          }
        }
      }
    }
    .comment-wrapper {
      font-size: $text-body;
      .h5 {
        font-size: $text-header;
        margin: 8px 0;
        font-weight: bold;
      }
      .field-name-field-rate-purity,
      .field-name-field-rate-comfort,
      .field-name-field-rate-personal {
        display: none;
      }
    }
    .media {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: start;
      align-items: flex-start;
    }
    .media-body {
      -ms-flex: 1;
      flex: 1;
    }
    .comment-inner {
      border-radius: 20px;
        border-top-left-radius: 0;
        position: relative;
        background-color: #f7f7f7;
        padding: 1rem 1.25rem;
        margin: 10px 0 0 20px;
    }
    #tab-comments .comment-inner {
      background-color: #FFF;
    }
    .comment-inner:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -16px;
        right: auto;
        top: 0px;
        bottom: auto;
        border: 18px solid transparent;
        border-top-color: #f7f7f7;
    }
    #tab-comments .comment-inner:after {
      border-top-color: #FFF;
    }
    .comment-content {
      font-size: $text-body;
      color: #000;
    }
    .comment-content .field-name-comment-body {
      white-space: pre-line;
    }
    .comment-avatar {
      width: 30px;
      border-radius: 4px;
    }
    .indented {
      margin-left: 30px;
    }
    .indented .comment-form {
      margin-left: 20px;
    }
  }

  .description {
    padding: 1rem;
    font-size: 14px;
    line-height: 20px;

    .node-body {
      iframe {
        width: 100% !important;
      }
      img {
        width: 100% !important;
        height: auto !important;
      }
      h1,h2,h3,h4 {
        font-size: $text-header;
      }
      .video-container {
        position: relative;
        padding-bottom: 56.25%;
        margin-bottom: 1rem;
        iframe {
           position: absolute;
           top: 0;
           left: 0;
           width: 100% !important;
           height: 100% !important;
        }
      }
    }

    p {
      margin-bottom: 1rem;
    }
    div.rtejustify {
      margin-bottom: 1rem;
    }

    ul {
      margin-left: 20px;
      margin-bottom: 20px;
      li {
        list-style: circle;
        margin-bottom: 4px;
      }
    }

    .collapsed-body {
        height: 150px;
        overflow: hidden;
        position: relative;
    }
    .collapsed-body::after {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 40px;
      background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
    }
    .timeline {
        position: relative;
        border-color: rgba(160, 175, 185, .15);
        padding: 0;
        margin: 0;

        .h6 {
          margin-bottom: 8px;
        }
        p {
          margin-bottom: 5px;
        }

        .avatar {
            position: relative;
            border-radius: 5px;
            box-shadow: 0 5px 10px 0 rgba(50, 50, 50, .15);
            width: 100px;
            height: 100px;
            float: left;
            margin-right: 10px;
        }

        .tl-item {
            margin-bottom: 10px;
            position: relative;
            display: -ms-flexbox;
            display: flex
        }
        .tl-item>* {
            padding: 10px
        }
        .tl-item .avatar {
            z-index: 2
        }
        .tl-item:last-child .tl-dot:after {
            display: none
        }
        .tl-item.active .tl-dot:before {
            border-color: #448bff;
            box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
        }
        .tl-item:last-child .tl-dot:after {
            display: none
        }
        .tl-item.active .tl-dot:before {
            border-color: #448bff;
            box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
        }
        .tl-dot {
            position: relative;
            border-color: rgba(160, 175, 185, .15)
        }
        .tl-dot:after,
        .tl-dot:before {
            content: '';
            position: absolute;
            border-color: inherit;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            top: 15px;
            left: 50%;
            transform: translateX(-50%)
        }
        .tl-dot:after {
            width: 0;
            height: auto;
            top: 25px;
            bottom: -15px;
            border-right-width: 0;
            border-top-width: 0;
            border-bottom-width: 0;
            border-radius: 0
        }
        tl-item.active .tl-dot:before {
            border-color: #448bff;
            box-shadow: 0 0 0 4px rgba(68, 139, 255, .2)
        }
        .tl-dot {
            position: relative;
            border-color: rgba(160, 175, 185, .15)
        }
        .tl-dot:after,
        .tl-dot:before {
            content: '';
            position: absolute;
            border-color: inherit;
            border-width: 2px;
            border-style: solid;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            top: 15px;
            left: 50%;
            transform: translateX(-50%)
        }
        .tl-dot:after {
            width: 0;
            height: auto;
            top: 25px;
            bottom: -15px;
            border-right-width: 0;
            border-top-width: 0;
            border-bottom-width: 0;
            border-radius: 0
        }
        .tl-content p:last-child {
            margin-bottom: 0
        }
    }
  }

  &.node-page {
    .description {
      padding-top: 0px;
    }
  }

  .map {
    padding: 1rem;

    h3 {
      font-size: 14px;
      margin-bottom: 1rem;
    }

    .leaflet-control-attribution {
      display: none;
    }

    iframe {
      width: 100%;
      margin-bottom: 1rem;
    }

    .map-bottom img {
      top: 5px;
      margin-left: 5px;
    }
    #hotel-detail-map {
      width: 100%;
      height: 400px;
      margin-bottom: 0px;
      position: relative;
        z-index: 1;
    }
    #trailsmap {
      height: 500px;
      width: 100%;
    }
    .elevation-control .background>g {
      transform: translate(60px, 40px);
    }
    .elevation-control .legend,
    .elevation-control .elevation-summary,
    #trailsmap .leaflet-pegman,
    #trailsmap .leaflet-control-locate,
    #trailsmap .search-button,
    #trailsmap .leaflet-top.leaflet-right,
    #trailsmap .leaflet-control-layers-list {
      display: none;
    }
    .elevation-summary {
      white-space: normal;
    }
  }

  .phone-call {
    width: calc(100vw - 2rem);
    padding: 1rem;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin-bottom: 0px;
    background-color: #F8F8FA;
    height: 1.6rem;
    z-index: 1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      font-size: 14px;
      color: #484849;
    }

    button {
      height: 33px;
      width: 71px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;

      @include flex-center;
    }
  }

  hr {
    margin: 0.5rem 1rem;
    border: 0;
    border-top: 0.2px solid #D9D9D9;
  }
}

.booking-button {
  position: relative;
  padding: 4px 16px 4px 40px;
  border: none;
  background-color: #893847;
  color: #FFF;
  border-radius: 20px;
  height: 40px;
  img {
    position: absolute;
    left: 13px;
    top: 8px;
  }
}

.more-link {
  position: relative;
  text-decoration: none;

  span {
    @include underline(#686777);
  }

  img {
    position: absolute;
    top: 2px;
  }
}

.page-navigation-cn,
.page__footer {
  margin: 15px auto;
  .item-list {
    ul {
      list-style: none;
      text-align: center;
      li {
        &.current,
        &.page-navigation-item,
        &.toggle-menu__item,
        &.pager-current,
        &.pager-ellipsis {
          display: none;
        }
        display: inline-block;
        margin: 5px;
        .icon-svg {
          height: 15px;
        }
        &.pager-first .icon-svg {
            transform: rotate(180deg);
            position: relative;
            top:0px;
        }
        a {
          display: block;
          border: 1px solid #DDD;
          background: #EEE;
          text-decoration: none;
          padding: 4px 8px;
          color: #000;
        }
      }
    }
  }
}

.active-filters {
  margin: 0;
  display: flex;
  overflow: scroll;
  padding-left: 1rem;
  gap:8px;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .col-auto {
    flex: 0 0 auto;
    width: auto;
    //margin-right: 5px;
    a {
      text-decoration: none;
      border-radius: 100px;
      padding: 5px 10px;
      color: #000;
      display: block;
      background: #F3F7FE;
      font-size: 13px;
      &.border-red {
        border: 1px solid #FF0000;
      }
      &.border-light {
        border: 1px solid #006ce4;
      }
      .icon-close svg {
        width: 10px;
        height: 10px;
        fill: #006ce4;
        position: relative;
        top: 0px;
        left: 4px;
      }
    }
  }
}

.catalogue-description {
  font-size: $text-body;
  h1,h2,h3 {
    font-size: $text-body !important;
    margin-bottom: 4px;
  }
  p {
    margin-bottom: 4px;
  }
  ul, ol {
    li {
      margin-left: 2rem;
    }
  }
  * {
    font-size: $text-body !important;
  }
}

.faq-wrapper {
  font-size: $text-body;
  padding: 1rem;
  .faq-list-inner {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
  }
}

footer {
  padding: 1rem;
  /*margin-top: 3rem;*/
  background-color: $footer-background;

  h2 {
    font-size: 12px;
    margin-bottom: 0.5rem;
  }

  #description {
    font-size: $text-body;
    margin-bottom: 1rem;
  }

  a {
    color: $base-color;
  }

  div {
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      gap: 1rem;
    }
  }

  #useful-links {
    margin-top: 1rem;
    div {
      display: flex;
      flex-direction: column;
      font-size: $text-body;
      gap: 0.4rem;
      text-decoration: underline;
    }
  }

  #footer-logo {
    margin-top: 1rem;
    width: 9rem;
  }
  p {
    font-size: 7px;
  }
}

.page-add,
.page-account-hotel {
  h5.card-header {
    padding: 3em 1em 0em 1em;
    font-size: $text-header;
  }
  .karpaty-add-info {
    padding: 1em;
    font-size: $text-body;
  }
  .hotel-add-edit {
    padding: 1em;
    font-size: $text-body;
    input.form-text,
    textarea {
      width: 100%;
      padding: 0.7rem;
      background-color: #FFF;
      border-radius: 6px;
      box-sizing: border-box;
      border: 0.5px solid #676767;
    }
    .upload-photos,
    .form-submit {
      height: 33px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;
      padding: 0 20px;
      margin-top: 10px;
    }
    .form-item {
      margin-bottom: 10px;
      label {
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
      }
      .form-checkboxes,
      .form-radios {
        .form-item {
          display: inline-block;
          margin-right: 10px;
          label {
            font-weight: normal;
            margin-bottom: 0;
            display: inline-block;
          }
        }
      }
    }
  }
}

#user-login, #user-register-form {
    margin: 40px 1rem !important;
    width: auto !important;
    input.form-text {
      width: 100%;
      padding: 0.7rem;
      background-color: #FFF;
      border-radius: 6px;
      box-sizing: border-box;
      border: 0.5px solid #676767;
    }
    .form-submit {
      height: 33px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;
      padding: 0 20px;
      margin-top: 10px;
    }
    .karpaty_auth_google a {
      width: auto !important;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
}
.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
}
.modal.show {
    display: block;
}
.modal-backdrop {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: rgba(15,21,32,0.7);
}
.fade {
    transition: opacity 0.15s linear;
}
.modal-backdrop.fade {
    opacity: 0;
}
.modal-backdrop.show {
    opacity: 0.5;
}
.modal-backdrop.show {
    opacity: 1;
    display: block;
}
.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    max-width: 600px;
    margin: 0 20px;
    top: 50%;
    transform: translate(0, -50%);
    .modal-content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        pointer-events: auto;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,0.2);
        border-radius: 0.3rem;
        outline: 0;
        border-color: rgba(28,39,60,0.16);
        border-radius: 0.25rem;
    }
    .modal-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 1rem 1rem;
        border-bottom: 1px solid rgba(72,94,144,0.16);
        border-top-left-radius: calc(0.3rem - 1px);
        border-top-right-radius: calc(0.3rem - 1px);
        position: relative;
        padding: 10px 15px 20px 20px;
        border-bottom-width: 0;
        display: flex;
        flex-direction: column;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        padding: 10px 20px;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        background-color: rgb(1, 88, 212);
        .event-title {
            color: #fff;
            font-size: 18px;
            font-weight: 400;
            margin-top: 30px;
            margin-bottom: 0;
            line-height: 1;
            order: 2;
            margin-top: 0;
            order: 1;
            line-height: 1.3em;
            font-size: 17px;
        }
        .nav-modal-event {
            align-self: auto;
            order: 2;
        }
        .nav-link svg {
            fill: #FFF;
        }
    }
    .modal-body {
        position: relative;
        flex: 1 1 auto;
        padding: 1rem;
        background-color: #fff;
        border-bottom-right-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
        padding: 25px;
    }
}

/* Leaflet buttons */
.map {
  .map-culture-icon {
    svg {
      fill: #5b9045;
      width: 32px;
      height: 32px;
    }
  }
  .map-water-icon {
    svg {
      fill: blue;
      width: 32px;
      height: 32px;
    }
  }
  .map-shelter-icon {
    svg {
      fill: red;
      width: 32px;
      height: 32px;
    }
  }
  .map-bottom {
    margin: 10px 0;
    font-size: 0.8rem;
    ul {
      list-style: none;
      margin-bottom: 8px;
      margin-top: 4px;
      li {
        svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  .marker-cluster-small div {
   background-color: rgba(110, 204, 57, 0.6);
  }
  .marker-cluster-medium div {
   background-color: rgba(240, 194, 12, 0.6);
  }
  .marker-cluster-large div {
     background-color: rgba(241, 128, 23, 0.6);
  }

  .marker-cluster {
    background-clip: padding-box;
    border-radius: 20px;
  }
  .marker-cluster div {
    width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-top: 0px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid #0000003d;
    text-decoration: none;
  }
  .marker-cluster span {
     line-height: 30px;
  }

  .leaflet-popup-close-button {
    display: none;
  }
  .leaflet-popup-content {
    margin: 5px;
    .lc-popup-fullmap-inner {
      width: 160px !important;
      height: 160px !important;
      border-radius: 12px;
      position: relative;
      img {
        width: 160px !important;
        height: 160px !important;
        border-radius: 12px;
      }
      .lc-popup-name {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        padding: 5px;
        background: transparent;
        background-color: rgba(26,26,26,0.5);
        color: #FFF;
        font-weight: normal;
        font-size: 13px;
        border-radius: 0 0 12px 12px;
      }
      .lc-popup-link {
        position: absolute;
        top:-38px;
        left: 0;
        background: #000;
        color: #FFF;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 12px;
        text-decoration: none;
      }
    }
  }

}
.easy-button-button {
  background: #FFF;
  border: none;
  padding: 0;
  margin: 0;
  width: 34px;
  height: 34px;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  &.show-culture-on-active,
  &.show-water-on-active,
  &.show-shelter-on-active {
    background: #DDD;
  }
}
.fa {
  display: block;
  width: 22px;
  height: 22px;
  margin: 4px;
  background-repeat: no-repeat;
  background-size: cover;
  &.fa-expand {
    background-image: url("/sites/all/themes/ui/lib/feather/icons/maximize-2.svg");
  }
  &.fa-area-chart {
    background-image: url("/sites/all/themes/uim/assets/images/culture.svg");
  }
  &.fa-tint {
    background-image: url("/sites/all/themes/uim/assets/images/water.svg");
  }
  &.fa-free-code-camp {
    background-image: url("/sites/all/themes/uim/assets/images/fire.svg");
  }
}
body.map-expanded .node-inner .map #hotel-detail-map,
body.map-expanded .node-inner .map #trail-detail-map {
  position: fixed;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 100%;
}

/* Helpers */
.mt-0 {
  margin-top: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
h3 a {
  color: #686777;
  position: relative;
  float: right;
  font-size: 12px;
  font-size: 13px;
  font-weight: normal;
}

/* New view */
.scrolled-wrapper {
  padding-right: 0 !important;
  padding-left: 0 !important;
  h3 {
    padding-left: 1rem;
    a {
      padding-right: 1rem;
    }
  }
  #travel-list.scrolled {
      overflow: scroll;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 0.7rem;
      padding-left: 1rem;
      padding-right: 0;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      .travel-card {
        display: block;
        box-shadow: none;
        .card-image {
          width: 200px;
          img {
            border-radius: 6px;
          }
        }
        .info-block {
          width: 100%;
        }
      }
  }
}
#popular.x2 {
  height: 220px;
  #scroll-container {
    .popular-item {
      flex: 0 0 8rem;
    }
  }
}

/* Catalogue header */
.catalogue-header {
  #search-block {
    height: 100px;
    #input-container {
      top: 155px;
      border-radius: 32px;
      .filter-info {
        margin-left: 15px;
        .filter-title {
          font-weight: bold;
          font-size: 14px;
          margin-bottom: 5px;
        }
        .filter-subtitle {
          font-size: 11px;
        }
      }
    }
  }
}

/* Filter */
#sidenav.filter {
  padding: 15px;
  right: 0;
  width: calc(100% - 30px);
  padding-top: 90px;
  padding-bottom: 90px;
  bottom: 0;
  height: auto;
  z-index: 1001;
  .filter-header {
    position: fixed;
    top:0;
    left: 0;
    right: 0;
    height: 60px;
    background: #686777;
    z-index: 9;
    #closebtn {
        position: absolute;
        top: 0;
        right: auto;
        font-size: 36px;
        margin-left: 15px;
        margin-top: 8px;
    }
    a.filter-reset {
        color: #FFF;
        font-size: 15px;
        font-weight: bold;
        display: block;
        right: 15px;
        position: absolute;
        top:5px;
    }
  }
  .filter-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right:0;
    height: 60px;
    background: #e3e3e3;
    z-index: 9;
    .filter-count {
      font-size: 14px;
      position: absolute;
      left:15px;
      top:22px;
    }
    .filter-submit {
      color: #FFF;
      font-weight: bold;
      font-size: 15px;
      padding: 0 15px;
      line-height: 33px;
      position: absolute;
      right:15px;
      height: 33px;
      width: 71px;
      background-color: #3CDC80;
      border-radius: 20px;
      border: none;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }
  .range-slider {
    margin-left: 10px;
    margin-right: 10px;
  }
}

.nothing-found {
  text-align: center;
  img {
    width: 90% !important;
    height: auto !important;
    margin: 0 auto;
  }
}

/* Map Page */
body.page-map {
  .leaflet-control-container {
    .leaflet-top.leaflet-left a {
      margin-top: 0 !important;
    }
  }
  .mkdf-smooth-transition-loader {
    top: 0!important;
  }
  #map::before {
    display: none;
  }
  .search-module {
    &:before {
      display: none;
    }
    .js-keywords-input {
      padding: 0 15px;
      width: calc(100% - 30px);
    }
  }
  .map-info-inner {
    width: calc(100% - 20px);
    padding-bottom: 0;
    #trail-elevation-profile {
      z-index: 2;
    	overflow: hidden;
    	background: #FFF;
      position: relative;
    	font-weight: 700;
    	font-size: 12px;
    	width: 100%;
    	max-width: auto;
    	height: 140px;
    	margin: 15px 0;
    }
    #trail-elevation-profile div {
    	position: absolute;
    }
    #trail-elevation-profile #trail-elevation-chart {
        right: 10px;
        top: 20px;
        width: 100%;
        max-width: 400px;
        height: 85px;
        background-size: 100%!important;
        background-position: bottom!important;
        vertical-align: top!important;
        background-repeat: no-repeat!important;
    }
    #trail-elevation-profile div#el-max,
    #trail-elevation-profile div#el-min {
      left: -10px;
      top: 20px;
    	width: 50px;
    	text-align: right
    }
    #trail-elevation-profile #pos-marker {
        border-left: 1px solid hsla(0,0%,100%,.6);
        height: 100%;
        z-index: 1;
        position: absolute;
        color: #fff;
        top:0;
        display: none;
    }
    #trail-elevation-profile #pos-marker-current-alt,
    #trail-elevation-profile #pos-marker-current-pos {
        color: #fff;
        margin-left: -20px;
        background-color: #008fe8;
        padding: 1px 2px;
        z-index: 3000;
        font-weight: 700;
        text-align: center;
        white-space: nowrap;
        border-radius: 2px;
        font-size: 12px;
    }
    #trail-elevation-profile #pos-marker-current-pos {
        bottom: -20px;
    }
    #trail-elevation-profile #pos-marker-current-alt {
        top: -10px;
    }
    #trail-elevation-profile div#el-min {
    	top: 90px
    }
    #trail-elevation-profile div.el-len {
    	bottom: 3px
    }
    #trail-elevation-profile div.el-len::before {
      content: '';
      position: absolute;
      left: 13px;
      top: -8px;
      height: 10px;
      width: 1px;
      background: #FFF;
    }
    #trail-elevation-profile div.el-len#el-len1::before {
      left: -3px;
    }
    #trail-elevation-profile div.el-len:last-child::before {
      left: auto;
      right: 0;
    }
    #trail-elevation-profile div#el-len1 {
    	left: 58px
    }
    #trail-elevation-profile div#el-len2 {
    	left: 150px
    }
    #trail-elevation-profile div#el-len3 {
    	left: 250px
    }
    #trail-elevation-profile div#el-len4 {
    	left: 350px
    }
    #trail-elevation-profile div#el-len5 {
    	right: 10px
    }
  }
}

/* Blog */
.blog-relation {
  .blog-object-review {
    .h4 {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    img {
      width: 70px;
      float: left;
      margin: 0 8px 8px 0;
    }
  }
}

#main-search {
  display: none;
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #FFF;
  padding: 2rem 1rem;
  #input-container {
    background: #F7F7F8;
    border-radius: 14px;
    position: relative;
    width: 100%;
    height: 54px;
    .clear {
      position: absolute;
      right:10px;
      top: 14px;
    }
    .back {
      position: absolute;
      left:10px;
      top: 14px;
    }
    input {
      border: none;
      position: absolute;
      font-size: 14px;
      background: transparent;
      left:40px;
      right:40px;
      height: 54px;
      outline:none;
      &:focus {
        font-size: 14px;
      }
    }
  }
  #results-container {
    margin: 15px 0;
    .main-search-info {
      font-size: 13px;
      margin-bottom: 13px;
    }
    .search-dropdown {
      .search-dropdown-line {
        margin-bottom: 10px;
        background-image: url("/sites/all/themes/uim/assets/images/arrow-up-left-search.svg");
        background-repeat: no-repeat;
        background-position: 100% 7px;
        padding-right: 30px;
        div {
          font-size: 13px;
        }
        span {
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}
body.global-search {
  overflow: hidden;
  #main-search {
    display: block;
    z-index: 20;
  }
}

/* Sorts */
.helper-container {
  height: auto;
  min-height: 46px;
  width: 100%;
  padding-top: 36px;
  box-shadow: 0 2px 8px 0 rgba(26,26,26,0.16);
  .helper-container-inner {
    box-sizing: border-box;
    position: relative;
    width: 100%;
    align-items: normal;
    padding: 0px 16px;
    border: unset;
    flex-wrap: nowrap;
    display: flex;
    button {
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: inherit;
      text-decoration: none;
      box-sizing: border-box;
      vertical-align: middle;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      background: transparent;
      flex-grow: 1;
      width: auto;
      text-align: start;
      color: #006ce4;
      .icon {
        position: relative;
        overflow: visible;
        display: inline-flex;
        fill: currentcolor;
        flex-shrink: 0;
        margin: 12px;
        height: 16px;
        .button-counter {
          position: absolute;
          top: 1px;
          right: -8px;
          display: block;
          padding: 0px 4px 1px 4px;
          background: #d4111e;
          color: #fff;
          border-radius: 8px;
          font-size: 11px;
        }
        svg {
          display: inline-block;
          vertical-align: top;
          height: 100%;
          width: auto;
        }
      }
    }
  }
}
#sort {
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: 20;
  box-sizing: border-box;
  border: none;
  padding: 0;
  position: fixed;
  background: transparent;
  background-color: rgba(26,26,26,0.5);
  inset: 0;
  outline: none;
  &.show {
    display: block;
  }
  .sort-inner {
    pointer-events: all;
    background: #FFF;
    box-shadow: 0 2px 8px 0 rgba(26,26,26,0.16);
    outline: none;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    height: auto;
    width: 100%;
    max-height: 100%;
    border-radius: 16px 16px 0 0;
    .header {
      padding: 1rem;
      position: relative;
      font-size: 16px;
      font-weight: bold;
      a#closebtn {
        position: absolute;
        right: 1rem;
        top: 6px;
        display: block;
        font-size: 28px;
      }
    }
    ul.sort-subnav {
      padding: 1rem;
      padding-top: 0;
      li {
        list-style: none;
        a {
          display: block;
          text-decoration: none;
          color: #000;
          padding: 8px 0;
        }
      }
    }
  }
}

/* MapBox custom control */
button.custom-move-to-left {
  margin-left: 10px;
  pointer-events: auto;
  padding: 2px 3px;
  background: #FFF;
  border: none;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.custom-move-to-left svg {
  width: 24px;
  height: 24px;
}
button.custom-move-to-right {
  margin-left: 10px;
  pointer-events: auto;
  padding: 2px 3px;
  background: #FFF;
  border: none;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.custom-move-to-right svg {
  width: 24px;
  height: 24px;
}
button.custom-move-to-up {
  margin-top: -36px;
  margin-left: 10px;
  pointer-events: auto;
  padding: 2px 3px;
  background: #FFF;
  border: none;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
  position: absolute;
}
.custom-move-to-up svg {
  width: 24px;
  height: 24px;
}
button.custom-move-to-down {
  margin-left: 10px;
  pointer-events: auto;
  padding: 2px 3px;
  background: #FFF;
  border: none;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 10%);
  border-radius: 4px;
}
.custom-move-to-down svg {
  width: 24px;
  height: 24px;
}
button.keydown-clicked {
  background-color: #FF0000;
}
body.node-type-history #mapboxmapinner .mapboxgl-ctrl-bottom-right {
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1;
}
body.node-type-history #mapboxmapinner .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: none;
  background: transparent;
  margin: 0;
}
body.node-type-history #mapboxmapinner .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: transparent;
  border: none;
  width: 100%;
  height: 100%;
  cursor: move;
}
body.node-type-history #mapboxmapinner .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-group button.mapboxgl-ctrl-compass .mapboxgl-ctrl-icon {
  display: none;
}
body.node-type-history #mapboxmapinner .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl-attrib {
  position: absolute;
  bottom: 0;
  right: 0;
}


/* Map Overlay */
body.overflow-hidden {
    overflow: hidden !important;
}
body.overflow-hidden #admin-menu {
    display: none !important;
}
.map-full-overlay-wrapper {
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 999;
    .show-filter-on-map {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      overflow: auto;
      align-items: center;
      flex-shrink: 0;
      box-sizing: border-box;
      background: none;
      position: absolute;
      z-index: 450;
      top: 10px;
      left: 56px;
      button {
        position: relative;
        vertical-align: top;
        display: inline-flex;
        align-items: center;
        color: #1a1a1a;
        border: 2px solid rgba(0,0,0,.2);
        padding: 8px 11px;
        border-radius: 5px;
        white-space: nowrap;
        max-width: none;
        background: #FFF;
        box-shadow: 0 2px 8px 0 rgba(26,26,26,0.16);
        font-weight: bold;
        .show-filter-on-map-inner {
          display: inline-block;
          fill: currentcolor;
          height: 16px;
          margin-inline-end: 8px;
          svg {
              display: inline-block;
              vertical-align: top;
              height: 100%;
              width: auto;
          }
        }
      }
    }
}
.map-full-overlay {
    display: block;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
    position: absolute;
}
.map-full-overlay-close {
    position: fixed;
    right: 10px;
    top: 10px;
    width: 30px;
    height: 30px;
    z-index: 1003;
    background: #fff;
    border-radius: 5px;
    color: #1a1a1a;
    border: 2px solid rgba(0, 0, 0, 0.2);
}
.map-full-overlay-close:before, .map-full-overlay-close:after {
    content: '';
    background: #383838;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 20px;
    height: 2px;
    border-radius: 4px;
}
.map-full-overlay-close:before {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.map-full-overlay-close:after {
    -webkit-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    -ms-transform: translateX(-50%) translateY(-50%) rotate(-45deg);
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}
.map-full-container {
    position: absolute;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    width: 100%;
    height: 100%;
}
.map-full-elements {
    position: fixed;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 5;
}
.map-full-elements-sidebar {
    z-index: 5;
    position: relative;
    width: 100%;
    height: 170px;
    &.elements-trails {
      height: 257px;
    }
}
.map-full-elements-sidebar #filters {
    padding: 15px;
}
.map-full-elements-sidebar #filters .show-on-map-wrapper {
    display: none;
}
.map-full-tiles {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 4;
    #map-full-map {
        width: 100%;
        height: 100%;
        position: relative;
        .maps-info {
          position: absolute;
          bottom: 10px;
          left: 10px;
          min-height: 100px;
          background: #FFF;
          border-radius: 10px;
          z-index: 9999;
          display: block;
          color: #000;
          padding-right: 10px;
          display: flex;
          text-decoration: none;
          box-shadow: 0px 0px 5px #0000007a;
          max-width: 460px;
          height: 130px;
          overflow: hidden;
          right:10px;
        }
        .maps-info .maps-info-image {
          height: 130px;
          width: 130px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        .maps-info .maps-info-image img {
          height: 130px;
          width: 130px;
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        .maps-info .maps-info-image div {
          position: absolute;
          background: #000000b0;
          color: #FFF;
          padding: 3px 10px;
          margin-top: 5px;
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          font-size: 10px;
        }
        .maps-info .maps-info-container {
          margin-left: 10px;
          height: 119px;
          overflow: hidden;
        }
        .maps-info .maps-info-container h3 {
          font-size: 14px;
          margin-top: 8px;
          line-height: 16px;
          margin-bottom: 4px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          max-height: 34px;
          text-overflow: ellipsis;
        }
        .maps-info .maps-info-container .maps-info-container-item {
          line-height: 13px;
        }
        .maps-info .maps-info-container .maps-info-container-text {
          line-height: 14px;
          margin-top: 5px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          max-height: 90px;
          text-overflow: ellipsis;
        }
    }
}
.objects-small-list {
    overflow: scroll;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 0.7rem;
    padding-left: 1rem;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
    a {
      display: block;
      position: relative;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 6rem;
      flex: 0 0 6rem;
      .lc-popup-link-trail {
        position: absolute;
        top:-26px;
        left: 0;
        background: #000;
        color: #FFF;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 12px;
        text-decoration: none;
      }
      .hotelsCard__image {
        img {
          height: 160px !important;
          width: auto !important;
        }
      }
      &.object-type-trail {
        .hotelsCard__image {
          img {
            height: 220px !important;
            width: auto !important;
          }
        }
      }
      .cardImage__leftBadge {
        position: absolute;
        top:5px;
        left: 0;
        background: green;
        color: #FFF;
        padding: 4px 8px;
        border-radius: 0 4px 4px 0;
        font-size: 11px;
      }
      .cardImage__bottomBadge {
        display: none;
      }
      .hotelsCard__content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 5px;
        background: transparent;
        background-color: rgba(26,26,26,0.5);
        .text-14,
        .card-price {
          color: #FFF;
          font-weight: normal;
          font-size: 12px;
          margin-top: 5px !important;
        }
        .card-address {
          display: none;
        }
        .hotelsCard__title {
          color: #FFF;
          font-weight: normal;
          font-size: 13px;
        }
      }
    }
}
.elements-trails {
  .objects-small-list {
    padding-top: 27px;
  }
}

/* Faq */
.faqs_container {
    background-color: #eee;
    position: relative;
    padding: 1rem;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 4px;
}
.faqs_container h3 {
    color: #171b1e!important;
    font-weight: bold;
    font-size: 1.5em;
}
.faq_tab .faq-list {
    margin-bottom: 15px;
    margin-top: 15px;
}
.faq_tab a:hover {
    text-decoration: underline;
}
.faq_tab .faq-list-inner {
    line-height: 1.125em;
    color: #0069a0;
    position: relative;
    border-top: 1px dotted #d7d7d7;
    padding: 12px 29px 12px 0;
    cursor: pointer;
    font-weight: 700;
}
.faq_tab .faq-list-inner:first-child {
    border-top: none;
}
.faq_tab .faq-list-inner.faq_open {
    background-image: none!important;
    cursor: default;
}
.faq_tab .faq-list-inner .open-faq {
    position: absolute;
    top: 8px;
    right: 0;
    cursor: pointer!important;
    font-size: 23px;
    pointer-events: none!important;
}
.faq_tab .faq-list-answer {
    line-height: 1.5em;
    color: #000000;
    font-size: .875em;
    border-bottom: none;
    padding-top: 12px;
    font-weight: normal;
}
.faq_tab .faq-list-answer, .faq_tab .faq-list-inner.faq_open .faq_control {
    display: none;
}

/* Account */
.hotel-add-edit .form-type-checkboxes .serivices-list .form-item label,
.hotel-add-edit .form-item-category .form-radios .form-item label {
  font-weight: normal;
}
.hotel-add-edit .form-type-checkboxes .serivices-list .form-item {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.hotel-add-edit .form-type-checkboxes .serivices-list .form-item label {
  display: inline;
  margin-bottom: 0;
}
.hotel-add-edit .form-type-checkboxes .serivices-list .form-item .form-checkbox {
  display: inline-block;
  margin-right: 3px;
}
.hotel-add-edit .services-collapsed .form-type-checkboxes > label {
  cursor: pointer;
}
.hotel-add-edit .services-collapsed .form-type-checkboxes > label::after {
  content: "";
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin: 0 0 0 5px;
  position: relative;
  top: -2px;
}
.hotel-add-edit .services-collapsed.open .form-type-checkboxes > label::after {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  top: 1px;
}
.hotel-add-edit .services-collapsed .serivices-list {
  display: none;
}
.hotel-add-edit .services-collapsed.open .serivices-list {
  display: grid;
}
.hotel-add-edit .d-none {
  display: none !important;
}
.hotel-add-edit .delete-image-undo {
  display: none;
}
.hotel-add-edit img {
  width: 100%;
  border-radius: 4px;
}
.hotel-add-edit .edit-photos-label {
  font-weight: bold;
}
.hotel-add-edit #edit-photos,
.hotel-add-edit .upload-previews {
  margin-top: 10px;
}
.hotel-add-edit .upload-previews .card,
.hotel-add-edit #edit-photos .card {
  padding: 0 !important;
}
.hotel-add-edit .upload-previews .btn-danger,
.hotel-add-edit #edit-photos .btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.hotel-add-edit .upload-previews .btn-success,
.hotel-add-edit #edit-photos .btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
}
.hotel-add-edit .upload-previews .form-item,
.hotel-add-edit #edit-photos .form-item {
  margin-bottom: 0;
}
.hotel-add-edit .upload-previews .form-item label,
.hotel-add-edit #edit-photos .form-item label {
  font-weight: normal;
  display: inline-block;
  margin-bottom: 0;
}
.hotel-add-edit .delete-uploaded-image,
.hotel-add-edit .delete-image-undo,
.hotel-add-edit .delete-image {
  position: absolute;
  top: 0px;
  left: 0px;
  padding: 5px;
  border-top-left-radius: 4px;
  border: none;
}
.hotel-add-edit .card.deleted img {
  opacity: 0.4;
}
.hotel-add-edit .card.deleted .delete-image {
  display: none;
}
.hotel-add-edit .card.deleted .delete-image-undo {
  display: block;
}
.hotel-add-edit .upload-previews .form-item-main-image,
.hotel-add-edit #edit-photos .form-item-main-image {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #0000009c;
  padding: 8px 10px 8px 10px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: none;
  z-index: 1;
  color: #fff;
}
.hotel-add-edit .form-item-main-image label {
  font-weight: normal;
}
.mtm-2 {
  margin-top: 2rem;
}

.slicknav_menu,
.d-none-mobile {
  display: none !important;
}

/* Account */
.select2-container {
  width: 100% !important;
  font-size: 13px;
  .select2-selection--multiple {
    border-radius: 6px;
    border: 0.5px solid #676767;
    padding: 0.5rem;
  }
}
.global-messages {
  padding: 1rem;
}
.account-inner {
  padding: 1rem;
  font-size: 14px;
  .account-tabs {
    ul.nav {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 0.4rem;
      margin-top: 1rem;
      li {
        list-style: none;
        margin: 0;
        padding: 0;
        a {
          display: block;
          text-align: center;
          padding: 7px 8px;
          text-decoration: none;
          white-space: nowrap;
          background: #EEE;
          border-radius: 4px;
          color: #000;
          &.active {
            color: #fff;
            background-color: #007bff;
          }
          &.btn-outline-danger {
            color: #dc3545;
            border-color: #dc3545;
          }
        }
      }
    }
  }
  .account-content {
    .d-flex {
      display: flex !important;
    }
    .ml-auto {
      margin-left: auto !important;
    }
    .items-center {
      align-items: center !important;
    }
    .flex-wrap {
      flex-wrap: wrap !important;
    }
    .bg-blue-1 {
      background-color: #0b3f6c;
    }
    .flex-center {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .d-none {
      display: none !important;
    }
    .feature-row {
      &.mb-4 {
        margin-bottom: 1rem;
      }
      .col-md-6 {
        padding: 1rem;
        border: 1px solid #EEE;
        background-color: #eee;
        margin-bottom: 1rem;
        border-radius: 0.5rem;
        .feature-item {
          text-decoration: none;
          h5 {
            margin-bottom: 5px;
            font-size: 16px;
          }
        }
      }
    }
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      word-wrap: break-word;
      background-color: #eee;
      background-clip: border-box;
      border: 0 solid #d9dee3;
      border-radius: 0.5rem;
      background-clip: padding-box;
      box-shadow: 0 2px 6px 0 rgb(67 89 113 / 12%);
      height: auto;
      box-sizing: border-box;
      margin-bottom: 1rem;
      padding: 1rem;
      .card-header {
          margin-bottom: 1rem;
          background-color: transparent;
          border-bottom: 0 solid #d9dee3;
          font-size: 16px;
          align-items: center;
          padding: 0;
          .card-title {
            font-size: 16px;
          }
          a.btn {
            text-align: center;
            padding: 7px 8px;
            text-decoration: none;
            white-space: nowrap;
            border-radius: 4px;
            color: #fff;
            background-color: #007bff;
            font-size: 14px;
            font-weight: normal;
          }
      }
      .table-responsive {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
      }
      table {
          caption-side: bottom;
          border-collapse: collapse;
          width: 100%;
          margin-bottom: 0;
          color: #697a8d;
          vertical-align: middle;
          border-color: #d9dee3;
          font-size: 14px;
          thead {
            padding-bottom: 0.5rem;
            border-bottom: 1px solid #d9dee3;
            th {
                color: #000;
                padding: 0.4rem;
                &:first-child {
                  text-align: left;
                  padding-left: 0;
                }
            }
          }
          tbody {
            td {
              padding: 0.4rem;
              background-color: transparent;
              border-bottom-width: 1px;
              box-shadow: inset 0 0 0 9999px transparent;
              color: #000;
              &.views-align-center {
                  text-align: center;
              }
              &:first-child {
                padding-left: 0;
              }
              &:last-child {
                padding-right: 0;
              }
            }
          }
      }
      .card-body {
        -ms-flex: 1 1 auto;
        flex: 1 1 auto;
        min-height: 1px;
        padding: 0;
        .media {
          .media-body {
            .mt-2 {
              margin-top: 0.5rem !important;
            }
          }
        }
      }
    }
    .divider-text {
        position: relative;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #000;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: .5px;
        margin: 15px 0;
        &::before,
        &::after {
          content: '';
          display: block;
          flex: 1;
          height: 1px;
          background-color: #e5e9f2;
        }
        &::before {
          margin-right: 10px;
        }
        &::after {
          margin-left: 10px;
        }
    }
    .cardImage {
      position: relative;
      z-index: 0;
      border-radius: inherit;
      &.ratio:before {
        display: block;
        width: 100%;
        content: "";
        padding-bottom: 100%;
      }
      .flag-wrapper {
        position: absolute;
        right: 8px;
        top: 8px;
        z-index: 2;
        .bookmark {
           border-color: rgb(229 231 235 / 1);
           border-width: 1px;
           border-radius: 4px;
           border-style: solid;
           background: #f3f3f3;
           color: rgb(51 65 85 / 1);
           width: auto;
           padding: 0;
           margin: 0;
           line-height: 1.25rem;
           font-size: .8125rem;
           display: inline-flex;
           div {
              padding-top: 0.3rem;
              padding-bottom: 0.3rem;
              padding-left: 0.45rem;
              padding-right: 0.45rem;
              svg {
                  fill: #94a3b8;
                  width: 1rem;
                  height: 1rem;
                  margin-right: 0.625rem;
                  display: block;
                  vertical-align: middle;
                  top: 3px;
                  fill: #dc3545;
                  margin-right: 0;
              }
          }
          span {
              border-left-width: 1px;
              padding-top: 0.35rem;
              padding-bottom: 0.35rem;
              padding-left: 0.625rem;
              padding-right: 0.625rem;
              border-color: #94a3b833;
              border-style: solid;
              padding-top: 0.3rem;
              padding-bottom: 0.3rem;
              padding-left: 0.425rem;
              padding-right: 0.425rem;
          }
        }
      }
      .cardImage__content {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
        border-radius: inherit;
        img {
            -o-object-fit: cover;
            object-fit: cover;
            transition: all 0.8s cubic-bezier(0.165,0.84,0.44,1);
            width: 100%;
            height: 100%;
        }
      }
      .cardImage__leftBadge {
        position: absolute;
        top: 20px;
        left: 0;
        div {
          padding: 5px 10px;
          background: #f8d448;
        }
      }
      .cardImage__bottomBadge {
        position: absolute;
        bottom: 10px;
        left: 10px;
        div {
          border-radius: 4px;
          color: #FFF;
          padding: 3px 5px;
          margin-right: 4px;
        }
      }
    }
    .hotelsCard__content {
      margin-top: 6px;
      margin-bottom: 15px;
      a.hotelsCard {
        text-decoration: none;
        color: #000;
      }
    }
    .table-objects-list {
      table, thead, tbody, th, td, tr {
    		display: block;
    	}
    	thead {
        border: none !important;
        tr {
    		   position: absolute;
    		   top: -9999px;
    		   left: -9999px;
         }
    	}
    	tr {
        border: 1px solid #ccc;
        border-radius: 5px;
      }
    	td {
    		border: none;
    		border-bottom: 1px solid #eee;
    		position: relative;
    		padding-left: 50%;
        text-align: center;
        a.btn {
          text-align: center;
          padding: 7px 8px;
          text-decoration: none;
          white-space: nowrap;
          border-radius: 4px;
          color: #fff;
          background-color: #007bff;
          font-size: 14px;
          font-weight: normal;
          display: block;
        }
        b {
          font-weight: normal;
        }
    	}
    	td:before {
    		white-space: nowrap;
    	}
      td:nth-of-type(2):before { content: "Найменування: "; }
      td:nth-of-type(3):before { content: "Адрес: "; }
      td:nth-of-type(4):before { content: "Активно: "; }
      td:nth-of-type(5):before { content: "Переглядів: "; }
    }
    .hotel-add-edit {
      padding: 0;
      font-size: 14px;
    }
    #karpaty-account-edit-form {
      input.form-text,
      textarea {
        width: 100%;
        padding: 0.7rem;
        background-color: #FFF;
        border-radius: 6px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        border: 0.5px solid #676767;
      }
      label {
        font-weight: bold;
        margin-bottom: 5px;
        display: block;
      }
      .form-submit,
      .btn {
        text-align: center;
        padding: 7px 8px;
        text-decoration: none;
        white-space: nowrap;
        border-radius: 4px;
        color: #fff;
        background-color: #007bff;
        font-size: 14px;
        font-weight: normal;
        display: block;
        border: none;
      }
      .password-confirm,
      .password-strength {
        display: none;
      }
      .form-item {
        margin-bottom: 8px;
      }
      .input-group {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-align: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        width: 100%;
        &.phone-input-group {
          label {
            display: none;
          }
          .form-item-phone {
            position: relative;
            -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
            flex: 1 1 auto;
            width: 1%;
            margin-bottom: 0;
          }
        }
        .input-group-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0.375rem 0.75rem;
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 400;
          line-height: 1.5;
          color: #495057;
          text-align: center;
          white-space: nowrap;
          background-color: #e9ecef;
          border: 1px solid #ced4da;
          border-radius: 0.25rem;
        }
        .input-group-prepend {
          margin-right: -1px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          .input-group-text {
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
          }
        }
        .input-group-append {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
        }
      }
      #edit-userphoto-row {
        margin-bottom: 1rem;
        img {
          margin-right: 8px;
        }
        p.mt-2 {
          margin-top: 0 !important;
          font-size: 11px;
        }
        #edit-avatar-upload {
          width: 100%;
        }
      }
    }
  }
}

/* Booking */
#hotels24_search .hotels_uk {
  width: 100% !important;
  text-align: center !important;
}
#hotels24_search .hotels_uk .people-count label {
  display: block !important;
  float: none !important;
  padding: 0 !important;
  padding-bottom: 3px !important;
}
.booking-popup {
  h2 {
    padding-top: 0;
    font-weight: 600;
    font-size: 22px;
  }
  #swal2-input {
    text-align: center;
    border: 1px solid #dd3233;
  }
  .swal2-html-container {
    line-height: 26px;
  }
  .swal2-footer {
    font-size: 14px;
  }
  .swal2-confirm {
    position: relative;
    padding-left: 46px;
    svg {
      width: 20px;
      height: 20px;
      position: absolute;
      left: 16px;
      top: 9px;
    }
  }
}
.rooms-booking {
  padding: 1rem;
  padding-top: 0;
  .datepicker {
    width: 100%;
    .datepicker__month-day--today:not(.datepicker__month-day--first-day-selected) {
        background-color: transparent !important;
        color: #333 !important;
    }
    .datepicker__month-day {
        padding: 14px 7px;
    }
    .datepicker__month {
      font-size: 16px;
    }
  }
  .address {
    margin-bottom: 10px;
    color: #893847;
    font-size: 12px;
  }
  .rooms-list {
      margin-top: 10px;
      .notifications-section {
        margin-bottom: 20px;
        color: #d82826;
        font-size: 14px;
        text-align: center;
        margin-top: 20px;
      }
      .room-main-info-block {
        margin-bottom: 7px;
      }
      hr {
        display: none;
      }
      .room-about-info {
        margin-top: 21px;
        border-top: 1px solid #0000002b;
        padding-top: 10px;
      }
      .room-about-photo {
        margin-bottom: 10px;
        overflow: scroll;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        img {
          width: 160px;
          height: 160px;
          margin-right: 6px;
        }
      }
      .booking-room {
        position: relative;
        padding: 8px 16px 8px 16px;
        border: none;
        background-color: rgb(40, 167, 69);
        color: #FFF;
        border-radius: 4px;
        height: 40px;
        text-decoration: none;
        font-size: 14px;
        padding-left: 46px;
        font-weight: 500;
        svg {
          position: absolute;
          left: 13px;
          height: 20px;
        }
      }
  }
  .rooms-list .fa,
  .rooms-list .hotel_warranty_block,
  .rooms-list #rooms_border,
  .rooms-list .number-about .room_desc,
  .rooms-list .number-about .flyPanel img,
  .rooms-list .menu-horiz,
  .rooms-list #default_block_text,
  .rooms-list .roomCountSelector,
  .rooms-list .prompt-underline.popup-orange-text,
  .rooms-list .hotel-price-table_button_column,
  .rooms-list .multiselect_category {
      display: none;
  }
  .rooms-list .room-table {
      background-color: #FFF;
      border-radius: 4px;
      color: #000;
      padding: 14px;
      margin-bottom: 14px;
      box-shadow: 8px 8px 40px 5px rgba(0,0,0,0.08);
  }
  .rooms-list .room-table>.tr {
      display: flex;
  }
  .rooms-list .room-table>.tr>.td {
      width: 100%;
  }
  .rooms-list .room-photo {
      position: relative;
      border-radius: 4px;
      width: 250px;
      margin-right: 14px;
      display: none;
  }
  .rooms-list .room-photo .room-photo-container {
      width: 250px;
      height: 250px;
      overflow: hidden;
      border-radius: 4px;
  }
  .rooms-list .room-photo img {
      border-radius: 4px;
  }
  .rooms-list .room-photo .room-photo-container img {
      width: 100%;
      height: 100%;
      cursor: pointer;
  }
  .rooms-list .room-photo .showReviewClass {
      position: absolute;
      top: 15px;
      left: 15px;
      text-decoration: none;
      padding: 4px 8px;
      text-transform: uppercase;
      color: white;
      font-weight: normal;
      font-size: 12px;
      text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      background: linear-gradient(to bottom, #725fbf, #5340a1);
      border-radius: 999px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      user-select: none;
  }
  .rooms-list .room-photo .showReviewClass span {
      width: 25px;
      height: 25px;
      position: absolute;
      top: -12px;
      right: -2px;
      transform: rotate(-20deg);
      filter: blur(0.5px);
  }
  .rooms-list .room-photo .showReviewClass span:before, .rooms-list .room-photo .showReviewClass span:after {
    content: "";
    position: absolute;
  }
  .rooms-list .room-photo .showReviewClass span:before {
    width: 1px;
    height: 100%;
    left: 12px;
    background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.7), transparent);
  }
  .rooms-list .room-photo .showReviewClass span:after {
    width: 100%;
    height: 1px;
    top: 12px;
    background: linear-gradient(to left, transparent, rgba(255, 255, 255, 0.7), transparent);
  }
  .rooms-list .room-photo .showReviewClass:before {
    content: "";
    position: absolute;
    z-index: -1;
    background: linear-gradient(30deg, #7f6dc5 60%, white);
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 999px;
  }
  .rooms-list .room-images {
    margin-top: 4px;
  }
  .rooms-list .room-images img {
    cursor: pointer;
    margin-bottom: 4px;
    width: 59px;
  }
  .rooms-list .tr:first-child .room-main-info h3 {
    font-size: 15px;
    margin-bottom: 8px;
    font-weight: 500;
  }
  .rooms-list .tr:first-child .room-main-info .tr {
    text-align: left;
  }
  .rooms-list .tr:first-child .room-main-info .tr>div {
    text-align: left;
  }
  .rooms-list .tr:first-child .room-main-info .room-main-info {
    display: flex;
    font-size: 15px;
  }
  .rooms-list .tr:first-child .room-main-info .room-main-info .guest_count_info::before {
    content: ", ";
  }
  .rooms-list .tr:first-child .room-main-info .room-main-info-list {
    display: none;
  }
  .rooms-list .tr:first-child .room-main-info .price-about .room-price-text,
  .rooms-list .tr:first-child .room-main-info .price-about .additional-discount-popup,
  .rooms-list .tr:first-child .room-main-info .price-about .warning {
    display: none;
  }
  .rooms-list .tr:first-child .room-main-info .price-about .price,
  .rooms-list .tr:first-child .room-main-info .price-about .price.price-new {
    font-weight: bold;
    border: none !important;
  }
  .rooms-list .tr:first-child .room-main-info .price-about .price.price-old {
    text-decoration: line-through;
    color: #333;
  }
  .rooms-list .room-about-info .flyPanel {
    display: none;
  }
  .rooms-list .room-about-info .room_desc,
  .rooms-list .room-about-info .flyPanel img {
    display: none;
  }
  .rooms-list .room-about-info .room-main-info {
    font-size: 13px !important;
    display: block !important;
  }
  .rooms-list .room-about-info .room-main-info .tr {
    display: block;
    margin-bottom: 6px;
  }
  .rooms-list .room-about-info .room-main-info .tr .td {
    text-transform: capitalize;
  }
  .rooms-list .room-about-info .room-main-info-text {
    font-weight: bold;
    flex: 0 0 250px;
  }
  .rooms-list .room-about-info .room-main-info-text b {
    font-weight: 500;
  }
  .rooms-list .roomDescription {
    display: none;
  }
  .rooms-list .room-pricing .room-main-info {
    margin-top: 8px;
  }
}

/* Rest */
.view-rest .view-content {
  margin-top: 25px;
}
.view-rest .view-content .views-row {
  margin-bottom: 10px;
}
.view-rest .view-content .views-row h2 {
  padding: 0 1rem;
}
.view-rest .view-content .views-row h2 a {
  font-size: 16px;
  text-decoration: underline;
}
.view-rest .view-content .views-row h2 a:hover {
  text-decoration: none;
}
.view-rest .view-content .views-row .views-field-body {
  margin-bottom: 10px;
  margin-top: 10px;
  padding: 0 1rem;
}
.view-rest #traveling {
  margin-top: 0;
}

/* Call Me */
.phone-call.phone-call-hotels{
  .h24-call-back-widget-container{
    width: 100%;
    .call-me-container {
       .btn {
         width: 100% !important;
         margin-top: 16px !important;
       }
       #call-me-container {
         top: -200px !important;
         left: -190px !important;
         position: absolute !important;
         width: 100% !important;
         display: none;
         form {
           width: 91% !important;
           &:before {
             display: none !important;
           }
           .form-group {
             img {
               display: none;
             }
             input {
               width: 99% !important;
             }
           }
         }
       }
    }
  }
}

/* Chat */
.karpaty_ai {
  button.chatbox-close {
    z-index: 2;
    background: transparent;
    box-shadow: none;
    bottom: auto;
    top: 7px;
    margin: 0;
  }
  .chatbox-popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    border-radius: 0;
    z-index: 1;
    .chatbox-popup__header {
      border-top-right-radius: 0;
      border-top-left-radius: 0;
      .chatbox-popup__logo {
        display: none;
      }
    }
    .chatbox-popup__main {
      min-height: calc(100% - 130px);
      height: calc(100% - 130px);
      .chatbox-messages {
        min-height: 100%;
      }
    }
  }
}

/* Blog fix */
.node-page-blog {
  .node-body {
    word-break: break-word;
    p {
      font-size: 14px !important;
      line-height: 20px;
    }
  }
}
.view-group-list {
  .card-body {
    word-break: break-word;
  }
}
